import React from "react";
import { Link } from "react-router-dom";
import "./index.scss";

function BannerEnterprise() {
  return (
    <>
      <section className="hero is-medium">
        <div className="hero-body has-text-centered">
          <p className="title py-4">Delivering Things Citywide</p>
          <p className="subtitle pb-6">
            Send/Receive packages citywide or Buy from your favorite brands and get it delivered in hours at courier rates
          </p>
          <a href="http://onelink.to/dbw2cy" className="button is-primary">
            Download app now
          </a>
        </div>
      </section>
    </>
  );
}

export default BannerEnterprise;

import React, { useEffect, useState } from 'react'
import TpConfig from '../../services/api/config';
import DeleteAccountApi from '../../services/api/delete-account';

const deleteAccountDataHelper = (data) => ({
     getDeleteNotes : () => {
        return data['notes'];
    },

     getDeleteRequestID : () => {
        return data['requestID'];
    },

     getDeleteRequestAllChecksPassed : () => {
        return data['allChecksPassed'];
    },

     getDeleteRequestFailedChecks : () => {
        const failedChecks = data['checks']
        .filter(check => !check['checkPassed'])
        .map(check => check['message']);
        return failedChecks;
    }
})

    
function DeleteAccount() {

    const [deleteAccountToRead, setDeleteAccountToRead] = useState([])
    const [userDeleteAccountChecksData, setUserDeleteAccountChecksData] = useState(null)
    const [userDeleteAccountCheckDataLoaded, setUserDeleteAccountCheckDataLoaded] = useState(false)
    const [userAccountCanBeDeleted, setUserAccountCanBeDeleted] = useState(false)
    const [getCheckFailedReason, setGetCheckFailedReason] = useState(null)

    const [isPhoneValid, setIsPhoneValid] = useState(false);
    const [phone, setPhone] = useState('');
    const [phoneNextClicked, setPhoneNextClicked] = useState(false);

    const [otp, setOtp] = useState('');
    const [isOtpValid, setIsOtpValid] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [retryCount, setRetryCount] = useState(0);
    const [retryTimer, setRetryTimer] = useState(60);
    const [timer, setTimer] = useState(null);

    const [accountDeleted, setAccountDeleted] = useState(false);

    useEffect(() => {
           TpConfig.getDeleteAccountNotes().then((response) => {
            if(response.data.status === "OK"){
                response.data.data.data.notes.length && setDeleteAccountToRead(response.data.data.data.notes)
            }
           }).catch((error) => console.error(error));
    }, [])

    useEffect(() => {
      if (retryTimer <= 0) {
        stopRetryTimer();
      }
    }, [retryTimer]);

    const fetchDeleteAccountChecks = (phoneNumber) => {
        DeleteAccountApi.getDeleteAccountChecks(phoneNumber).then((response) => {
            if(response.data.status === "OK"){
                setUserDeleteAccountChecksData(response.data.data)
                setUserDeleteAccountCheckDataLoaded(true)
                const allChecksPassed = deleteAccountDataHelper(response.data.data).getDeleteRequestAllChecksPassed();
                if (allChecksPassed) {
                  setUserAccountCanBeDeleted(true);
                  requestDeleteAccountOtp(deleteAccountDataHelper(response.data.data).getDeleteRequestID());
                }
            }
          }).catch((error) => setGetCheckFailedReason("No user found with given phone number"));
    }

    const startRetryTimer = () => {
      setRetryTimer(60);
      const timer = setInterval(() => {
        setRetryTimer(retryTime => retryTime - 1)}, 1000);
      setTimer(timer);
    }

    const stopRetryTimer = () => {
      clearInterval(timer);
      setTimer(null);
    }

    const requestDeleteAccountOtp = (requestID) => {
        DeleteAccountApi.requestDeleteAccountOtp(requestID).then((response) => {
            setOtpSent(true);
            setRetryCount(retryCount + 1);
            startRetryTimer();
          }).catch((error) => console.error(error));
    }

    const requestDeleteAccount = () => { 
      const requestID = deleteAccountDataHelper(userDeleteAccountChecksData).getDeleteRequestID();
        DeleteAccountApi.requestDeleteAccount(requestID, otp).then((response) => {
            setAccountDeleted(true);
          }).catch((error) => {
            setIsOtpValid(false)
            setClicked(false)
          });
    }

    const UserPhoneSubmit = () => {

      const handlePhoneChange = (e) => {
        if (e.target.value.length < 11 && /^\d*$/.test(e.target.value)) {
          if (getCheckFailedReason) {
            setGetCheckFailedReason(null);
            setPhoneNextClicked(false);
          }
          setPhone(e.target.value);
          validatePhone(e.target.value);
        }
    }

      const validatePhone = (phoneNumber) => {
        const regex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
        setIsPhoneValid(regex.test(phoneNumber));
      }

      const onNextClick = () => {
        if (isPhoneValid) {
          fetchDeleteAccountChecks(phone);
          setPhoneNextClicked(true);
        }
      }

      
      return (
      <div className='active-card'>
        <p>Please enter your phone number to continue</p>
        <div>+91{"  "}
        <input type="text" placeholder="Phone Number" onChange={handlePhoneChange} value={phone}/>{"  "}
        {phone.length&&!isPhoneValid?"⚠️":""}{"  "}
        <small>{phone.length&&!isPhoneValid&&!getCheckFailedReason?"Phone number invalid":""}{"  "}</small>
        <small>{getCheckFailedReason?"⚠️ Phone number not found":""}</small>
        </div>
        {<button className='primary-button' onClick={onNextClick} disabled={!isPhoneValid || phoneNextClicked}>Next</button>}
      </div>
    )}

    const ShowFailedChecks = () => {
      return (
        <div className='active-card'>
          <p><b>Sorry, we can't delete your account because of the following reasons:</b></p>
          <br/>
          <div className="list">{deleteAccountDataHelper(userDeleteAccountChecksData).getDeleteRequestFailedChecks().map((check, index) => (<p key={index}>{"•"}{"  "}{check}</p>))}</div>
        </div>
      )
    }

    const VerifyOTPtoDeleteAccount = () => {

      const handleOtpChange = (e) => {
        if (e.target.value.length < 5) {
          setOtp(e.target.value);
          validateOtp(e.target.value);
        }
      }

      const validateOtp = (otp) => {
        const regex = /^\d{4}$/;
        setIsOtpValid(regex.test(otp));
      }

      const onDeleteAccountClick = () => {
        if (isOtpValid) {
          setClicked(true);
          requestDeleteAccount()
        }
      }

      const onResendClick = () => {
        setOtpSent(true);
        setRetryCount(retryCount + 1);
        requestDeleteAccountOtp(deleteAccountDataHelper(userDeleteAccountChecksData).getDeleteRequestID());
      }


      return (
        <div className='active-card'>
          <p>Enter the OTP sent to your phone number</p>
          <input type="text" placeholder="OTP" onChange={handleOtpChange} value={otp}/>
          <small>{otp.length&&!isOtpValid?"OTP invalid":""}</small>
          <br/>
          {otpSent && retryTimer > 0 && <small>OTP sent, retry in {retryTimer} seconds {otpSent?"sent": "not sent"}</small>}
          {otpSent && retryTimer === 0 && <small>OTP sent, retry now</small>}
          {otpSent && retryTimer === 0 && <button className='primary-button' onClick={onResendClick}>Resend OTP</button>}
          {<button className='primary-button' onClick={onDeleteAccountClick} disabled={!isOtpValid || clicked}>Delete Account</button>}
        </div>
      )

    }
    
    if(accountDeleted){
      return (
        <>
        <style>
          {
            `
            .account-deleted-message {
              position: relative;
              width: 100%;
              max-width: 960px;
              margin: 0 auto;
              padding: 0 20px;
              box-sizing: border-box; 
              margin-top: 50px;
            }
            `
          }
        </style>
        <div className='account-deleted-message'>
          <h1> <b>Account Deleted</b> </h1>
          <p>Your account has been deleted. You will be logged out automatically.</p>
        </div>
        </>
      )
    }

    return (
        <>
        <style>
          {
            `
            .active-card {
              background-color: #fff;
              border-radius: 4px;
              padding: 10px;
              margin: 10px 0;
              display: flex;
              flex-direction: column;
              justify-content: start;
              align-items: start;
            }
            .active-card p {
              margin: 0;
            }
            .active-card input {
              margin: 10px 0;
              padding: 10px;
              border: 1px solid #ddd;
              border-radius: 4px;
            }
            .primary-button {
              background-color: #ffac23;
              padding: 5px 15px;
              border: none;
              border-radius: 25px;
              cursor: pointer;
              margin-top: 10px;
            }
            .list {
              display: flex;
              flex-direction: column;
              justify-content: start;
              align-items: start;
              font-size: 14px;
              font-weight: 400;
              color: #333;
              margin-top: 10px;
            }
            .delete-container {
              position: relative;
              width: 100%;
              max-width: 960px;
              margin: 0 auto;
              padding: 0 20px;
              box-sizing: border-box; 
              margin-top: 50px;
            }
            
            /* For devices larger than 400px */
            @media (min-width: 400px) {
              .delete-container {
                width: 85%;
                padding: 0; }
            }

            /* For devices larger than 550px */
            @media (min-width: 550px) {
              .delete-container {
                width: 80%; }
            }

            /* For devices larger than 700px */
            @media (min-width: 700px) {
              .delete-container {
                width: 70%; }
            }

            /* For devices larger than 960px */
            @media (min-width: 960px) {
              .delete-container {
                width: 60%; }
            }

            /* For devices larger than 1200px */
            @media (min-width: 1200px) {
              .delete-container {
                width: 50%; }
            }
            `
          }
        </style>
        <div className='delete-container'>
          <h1> <b>Delete Account</b> </h1>
          {
            !deleteAccountToRead.length
            ? ("Loading ...")
            : <div className='list'>{deleteAccountToRead.map(
                (line, index) => (
                    <p key={index}>{"•"}{"  "}{line}</p>
                )
            )}</div>
          }
          <hr />
          {
            !userDeleteAccountCheckDataLoaded && (UserPhoneSubmit(fetchDeleteAccountChecks))
          }
          {
            userDeleteAccountCheckDataLoaded && !userAccountCanBeDeleted && (ShowFailedChecks())
          }
          {
            userDeleteAccountCheckDataLoaded && userAccountCanBeDeleted && (VerifyOTPtoDeleteAccount())
          }
        </div>
        </>
      )
}
    
export default DeleteAccount
   
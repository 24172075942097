import React from "react";

import whyManageImage from "../../assets/images/enterprises/enterprisewhy@2x.webp";
import whyCuttingImage from "../../assets/images/enterprises/Business@2x.webp";

function WhyD2C() {
  const data = {
    p1: [
      {
        title: "End-To-End Fulfillment As A Service",
        content:
          "Ship your Inventory and start selling across metros. Telyport will take care of delivering excellence across value chain until the product reaches your customers hands, SAME DAY irrespective of source location",
        img: "",
      },
      {
        title: "Technology & Business Analytics",
        content:
          "Now, get state of the art technology & features to your business and customers at no additional technology cost. Including Dashboards, Tech Integrations across Omni-Channels, Warehousing, Last mile and CRM",
        img: "",
      },
      {
        title: "Scale At Will",
        content:
          "Scaling Your Sales Campaigns has never been this easy... Telyport's Deep Tech adapts to the Fulfillment capacities of your Sales Campaigns and automatically scales the different layers of the Fulfillment Value Chain",
        img: "",
      },
      {
        title: "Payments Simplified",
        content:
          "Payments with PCI Compliance. CODs, Settlements, Reconciliations and so on are on-demand and simplified",
        img: "",
      },
    ],
    p2: [
      {
        title: "APIs/Channel Integrations",
        content: "Integrations across major omni-channel marketplaces, warehousing, last mile available via pre-built plugins as well as native APIs that can be extended to suit any business specific requirements",
        img: "",
      },
      {
        title: "Intelligent Inventory Management & Stock Forecasting",
        content: "Get state of the art technology benefits like Intelligent Inventory Management, Stock Forecasting to never run out of stock across categories, WELL IN ADVANCE!",
        img: "",
      },
      {
        title: "Admin Dashboard",
        content: "Track all your on-going orders realtime including ETAs, Status and much more. Empowering enterprises to make Informed Decisions",
        img: "",
      },
    ]
  };

  return (
    <>
      <h2 className="is-size-3 has-text-weight-bold has-text-centered my-6">
        Why Telyport for D2C Brands?
      </h2>

      <div className="container is-fluid-custom">
        <div className="columns">
          <div className="column is-6">
            <h3 className="is-size-6 has-text-weight-bold mb-6">
            Delivery to your Customers as fast as 2 Hrs without the hassle of Marketplace Commissions, Operational Overheads or Capacity Constraints 
            </h3>
            {data.p1.map((item, index) => {
              return (
                <div className="columns" key={index}>
                  <div className="column is-1"></div>
                  <div className="column is-11">
                    <h4 className="is-size-6 has-text-weight-bold">
                      {item.title}
                    </h4>
                    <p className="is-size-6">{item.content}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="column is-6">
            <img src={whyManageImage} alt="Why Telyport for Enterprise" />
          </div>
        </div>
      </div>
      <div className="container is-fluid-custom">
        <div className="columns">
          <div className="column is-6">
            <img src={whyCuttingImage} alt="Why Telyport for Enterprise" />
          </div>
          <div className="column is-6">
            <h3 className="is-size-6 has-text-weight-bold mb-6">
            Deep Tech Capabilities & Technology That Augments Your Business Operations
            </h3>
            {data.p2.map((item, index) => {
              return (
                <div className="columns">
                  <div className="column is-1"></div>
                  <div className="column is-11">
                    <h4 className="is-size-6 has-text-weight-bold">
                      {item.title}
                    </h4>
                    <p className="is-size-6">{item.content}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default WhyD2C;

import React from "react";

import whyManageImage from "../../assets/images/enterprises/enterprisewhy@2x.webp";
import whyCuttingImage from "../../assets/images/enterprises/Business@2x.webp";

function WhyD2C() {
  const data = {
    p1: [
      {
        title: "Home Deliveries Simplified",
        content:
          "Affordable & Convenient Citywide Deliveries bringing efficiencies and efficacies at optimal cost with no MOQ barrier, bound to delight your customer's experience",
        img: "",
      },
      {
        title: "Technology & Business Analytics",
        content:
          "Get access to Telyport array of products from Mobile/Web Apps to API Integrations via state of the art technology features including Live Tracking, Data Access and much more",
        img: "",
      },
      {
        title: "Payments Simplified",
        content:
          "Pay anytime, before or after delivery via multiple payment options (UPI, Net Banking, Credit/Debit Cards) along with additional payment features such as Shared Wallets for Businesses, CODs",
        img: "",
      },
      {
        title: "Scale At Will",
        content:
          "With TELYPORTs well crafted last mile experience for new age consumers, enrich the overall buying experience of your customers and scale at will for peak demands at no operational overheads",
        img: "",
      },
    ],
    p2: [
      {
        title: "Products/APIs",
        content: "Get Access to TELYPORTs array of products such as Mobile Apps, Web Apps, APIs, Channel Integrations, Realtime Order Tracking and much more at no additional cost to your business or your customers",
        img: "",
      },
      {
        title: "Shared Wallet",
        content: "Whether you are a Multi Outlet Home Office or a Franchise Business, With Telyports Shared Wallet feature, your business users get the ability to choose and pay from business's Shared Wallet or let them use Personal Wallet for centralized payment tracking",
        img: "",
      },
      {
        title: "Admin Dashboard",
        content: "Track all your business operations including realtime multi-order tracking from a centralized dashboard, also get access to historical data along with customer information, order trends and a wide range of analytical capabilities",
        img: "",
      },
    ]
  };

  return (
    <>
      <h2 className="is-size-3 has-text-weight-bold has-text-centered my-6">
        Why Telyport for MSMEs/Storefronts/Darkstores?
      </h2>

      <div className="container is-fluid-custom">
        <div className="columns">
          <div className="column is-6">
            <h3 className="is-size-6 has-text-weight-bold mb-6">
            Whether you are a Restaurant, Meat Shop, Cloud Store or an Artisan, Reach your customer's doorstep with Telyport and Power your Offline/Digital Sales
            </h3>
            {data.p1.map((item, index) => {
              return (
                <div className="columns">
                  <div className="column is-1"></div>
                  <div className="column is-11">
                    <h4 className="is-size-6 has-text-weight-bold">
                      {item.title}
                    </h4>
                    <p className="is-size-6">{item.content}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="column is-6">
            <img src={whyManageImage} alt="Why Telyport for Enterprise" />
          </div>
        </div>
      </div>
      <div className="container is-fluid-custom">
        <div className="columns">
          <div className="column is-6">
            <img src={whyCuttingImage} alt="Why Telyport for Enterprise" />
          </div>
          <div className="column is-6">
            <h3 className="is-size-6 has-text-weight-bold mb-6">
            Deep Tech Capabilities & Technology That Augments Your Business Operations
            </h3>
            {data.p2.map((item, index) => {
              return (
                <div className="columns">
                  <div className="column is-1"></div>
                  <div className="column is-11">
                    <h4 className="is-size-6 has-text-weight-bold">
                      {item.title}
                    </h4>
                    <p className="is-size-6">{item.content}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default WhyD2C;

import React, {useEffect, useState} from "react";

function ContactUs() {

  const [name, setName] = useState("");
  const [invalidName, setInvalidName] = useState(false);
  const [email, setEmail] = useState("");
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [phone, setPhone] = useState("");
  const [invalidPhone, setInvalidPhone] = useState(false);
  const [businessName, setBusinessName] = useState("");
  const [invalidBusinessName, setInvalidBusinessName] = useState(false);
  const [businessLocation, setBusinessLocation] = useState("");
  const [invalidBusinessLocation, setInvalidBusinessLocation] = useState(false);
  const [message, setMessage] = useState("");
  const [formValid, setFormValid] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  
  const checkFormValidity = () => {
    if (name!=="" && email!=="" && phone!=="" && businessName!=="" && businessLocation!=="") {
      if(!invalidName && !invalidEmail && !invalidPhone && !invalidBusinessName && !invalidBusinessLocation) {
      setFormValid(true);
      }
    } else {
      setFormValid(false);
    }
  }

  useEffect(() => {
    checkFormValidity();
  }, [name, email, phone, businessName, businessLocation]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const url = "https://telyport.com/api/v2/beta/m/biz/reach"
    const data = {
      name: name,
      email: email,
      phone: phone,
      businessName: businessName,
      businessLocation: businessLocation,
      msg: message
    }
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
    .then(res => res.json())
    .then(res => {
      setFormSubmitted(true);
    }
    )
    .catch(err => console.error(err));
  };

  const handleNameChange = (e) => {
    if (e.target.value.length < 4) {
      setInvalidName(true);
    } else {
      setInvalidName(false);
    }
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    const emailRegex = new RegExp(/^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/);
    if (!emailRegex.test(e.target.value)) {
      setInvalidEmail(true);
    } else {
      setInvalidEmail(false);
    }
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    const phoneRegex = new RegExp(/^[0-9]{10}$/);
    if (!phoneRegex.test(e.target.value)) {
      setInvalidPhone(true);
    } else {
      setInvalidPhone(false);
    }
    setPhone(e.target.value);
  };

  const handleBusinessNameChange = (e) => {
    if (e.target.value.length < 4) {
      setInvalidBusinessName(true);
    } else {
      setInvalidBusinessName(false);
    }
    setBusinessName(e.target.value);
  };

  const handleBusinessLocationChange = (e) => {
    if (e.target.value.length < 4) {
      setInvalidBusinessLocation(true);
    } else {
      setInvalidBusinessLocation(false);
    }
    setBusinessLocation(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };




  return (
    <>
     <style>
{`
.contact-box{
  margin: 2rem 2rem 0 0;
  border-radius: 3rem 3rem 3rem 0;
  padding: 2rem;
  border: solid 1px #ddd;
  position: relative;
  box-shadow: none;
  background-color: #fff;
}

.contact-box:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f2f5f7;
    box-shadow: -2rem 2rem 0 0 #f2f5f7;
    border-radius: 3rem 3rem 3rem 0;
  transform: rotate(180deg);
  z-index: -10;
    
}

.contact-box-submit{
  margin: 1.25rem 0 0 1px;
  padding: 1rem;
  width: 100%;
  border-radius: 1.75rem 1.75rem 1.75rem 0;
  background-color: #ffb130;
  font-size: 16px;
  font-weight: 600;
}

#snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: #ffb130;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
  font-size: 17px;
  border-radius: 25px;
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

.field-error {
    color: red;
    font-size: small;
}

@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;} 
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;} 
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}
`}
  </style>

      <div className="nav py-5" id="myTopnav">
      </div>

      <div className="bannerInfo" id = "bannerInfoID" style={{display: "none"}}>
      </div>
    
  <div className="container my-4 px-4">
    <div className="columns is-tab">
        <div className="column">
          <div className="is-size-3 block" style={{color: "#ffb130"}}>Contact Us</div>
          <div className="is-size-4 block">Reach your customers doorstep with Telyport</div>
          <div className="business-info">Empowering On-Demand deliveries citywide at courier rates. Reach out to us for learning more about our services and pricing</div>
        </div>
        <div className="column">
          <div className="box contact-box">
            <div className="title is-4 block">
              Talk to us
            </div>
            <form id="business-reach-form" onSubmit={handleSubmit}>
              <div className="field">
                <div className="control">
                  <input className="input is-rounded" type="text" name="name" placeholder="Name" id="name" required 
                  value={name} onChange={handleNameChange}/></div>
                {invalidName && <p id="reach-err-name" className="is-danger ml-2 field-error" >Name is a required field</p>}
              </div>
              <div className="field">
                <div className="control">
                  <input className="input is-rounded" type="email" name="email" placeholder="Email" id="email" required 
                  value={email} onChange={handleEmailChange} /></div>
                { invalidEmail && <p id="reach-err-email" className="is-danger ml-2 field-error" >Email is a required field</p>}
              </div>
              <div className="field">
                <div className="control">
                  <input className="input is-rounded" type="tel" name="phone" placeholder="Phone" id="phone" required 
                  value={phone} onChange={handlePhoneChange}/></div>
               {invalidPhone && <p id="reach-err-phone" className="is-danger ml-2 field-error" >Phone number is not valid</p>}
              </div>
              <div className="field">
                <div className="control">
                  <input className="input is-rounded" type="text" name="business-name" placeholder="Business Name" id="business-name" required 
                  value={businessName} onChange={handleBusinessNameChange}/></div>
                {invalidBusinessName && <p id="reach-err-business-name" className="is-danger ml-2 field-error" >Business name is a required field</p>}
              </div>
              <div className="field">
                <div className="control">
                  <input className="input is-rounded" type="text" name="business-location" placeholder="Business Locations" id="business-location" required 
                  value={businessLocation} onChange={handleBusinessLocationChange}/></div>
                {invalidBusinessLocation && <p id="reach-err-business-location" className="is-danger ml-2 field-error" >Location is a required field</p>}
              </div>
              <div className="field">
                <div className="control">
                  <textarea className="textarea is-small" rows="3" placeholder="Anything else that we should know?" id="business-msg"
                  value={message} onChange={handleMessageChange}> </textarea></div>
              </div>
    <button className="button contact-box-submit" id="biz-reach-submit" type="submit" disabled={!formValid || formSubmitted}>
      {formSubmitted?<b>{"Thank you for reaching us :)"}</b>:<b>Submit</b>}
      </button>
            </form>
          </div>
        </div>
    </div>
  </div>

    </>
    );
}

export default ContactUs;
import React from "react";
import { Link } from "react-router-dom";
import "./index.scss";

function BannerMSME() {
  return (
    <>
      <section className="hero is-medium">
        <div className="hero-body has-text-centered">
          <p className="title py-4">
            Doorstep Deliveries For Businesses Of Any Size, Share, Form and
            Nature
          </p>
          <p className="subtitle pb-6">
            Whether you are a storefront, darkstore or any MSME, Now reach your
            customer's doorstep Just In time/2Hrs/4Hrs/Same Day/Next Day
          </p>
          <a
            href="https://console.telyport.com/register"
            className="button is-primary"
          >
            Get Started
          </a>
        </div>
      </section>
    </>
  );
}

export default BannerMSME;

import { axiosClient } from "."

export const CONFIGS = {
    DELETE_USER_ACCOUNT_NOTES: "delete_user_account_notes",
}

const url = "m/config/"

const TpConfig = {
    getDeleteAccountNotes: () => {
        return axiosClient.get(url + CONFIGS.DELETE_USER_ACCOUNT_NOTES)
    }
}

export default TpConfig
// Path: landing-react/src/services/api/config.js
import React from "react";
import FootNote from "../../sub-components/footnote";
import "./index.scss";

import Banner from "./banner";
import WhyD2C from "./why";

function MSME() {
  return (
    <>
      <div className="container is-fluid-custom is-max-widescreen">
        <Banner />
        <WhyD2C />
      </div>
      <FootNote />
    </>
  );
}

export default MSME;

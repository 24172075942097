import React from "react";
import { Link } from "react-router-dom";
import "./index.scss";

function BannerEnterprise() {
  return (
    <>
      <section className="hero is-medium">
        <div className="hero-body has-text-centered">
          <p className="title py-4">On-Demand Deliveries for your business from ₹ 40</p>
          <p className="subtitle pb-6">
            Reach your customers citywide in 2 Hrs, 4 Hrs or Sameday with customized flat rate pricing. Telyport's platform is built for businesses to afford delivering on-demand to win customers confidence.
          </p>
          <a href="https://console.telyport.com/register" className="button is-primary">Get Started</a>
        </div>
      </section>
    </>
  );
}

export default BannerEnterprise;

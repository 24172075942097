import { axiosClient } from ".";

const DELETE_ACCOUNT_URL = "m/user/public/account_deletion"
const DELETE_AGENT_ACCOUNT_URL = "m/agent/public/account_deletion"

const DeleteAccountApi = {
    getDeleteAccountChecks : (phoneNumber) => {
        return axiosClient.get(`${DELETE_ACCOUNT_URL}`, {
            params: {
                mobile: phoneNumber
            }
        })
    },
    requestDeleteAccountOtp : (requestID) => {
        return axiosClient.put(`${DELETE_ACCOUNT_URL}`, {
            requestID: requestID
        })
    },
    requestDeleteAccount : (requestID, otp) => {
        return axiosClient.delete(`${DELETE_ACCOUNT_URL}`, {
            params: {
                requestID: requestID,
                otp: otp
            }
        })
    },
    getDeleteAgentAccountChecks : (phoneNumber) => {
        return axiosClient.get(`${DELETE_AGENT_ACCOUNT_URL}`, {
            params: {
                mobile: phoneNumber
            }
        })
    },
    requestDeleteAgentAccountOtp : (requestID) => {
        return axiosClient.put(`${DELETE_AGENT_ACCOUNT_URL}`, {
            requestID: requestID
        })
    },
    requestDeleteAgentAccount : (requestID, otp) => {
        return axiosClient.delete(`${DELETE_AGENT_ACCOUNT_URL}`, {
            params: {
                requestID: requestID,
                otp: otp
            }
        })
    }
}

export default DeleteAccountApi;
// Path: landing-react/src/services/api/delete-account.js
import React from "react";

function TermsCondition() {
  return (
    <>
      <style>
        {`
                h1 {
                    font-size: 2.5rem;
                    font-weight: bold;
                }

                h2 {
                    font-size: 1.5rem;
                    font-weight: bold;
                }

                
              `}
      </style>
      <div className="container is-fluid-custom is-max-widescreen my-6">
        <h2 _ngcontent-c14="">Terms and Conditions ("Terms")</h2>
        <p _ngcontent-c14="">Last updated: September 20, 2018</p>
        <p _ngcontent-c14="">
          Please read these Terms and Conditions ("Terms", "Terms and
          Conditions") carefully before using the www.telyport.com website (the
          "Service") operated by Telyport ("us", "we", or "our").
        </p>
        <p _ngcontent-c14="">
          Your access to and use of the Service is conditioned on your
          acceptance of and compliance with these Terms. These Terms apply to
          all visitors, users and others who access or use the Service.
        </p>
        <p _ngcontent-c14="">
          By accessing or using the Service you agree to be bound by these
          Terms. If you disagree with any part of the terms then you may not
          access the Service.
        </p>
        <h2 _ngcontent-c14="">
          <strong _ngcontent-c14="">
            <strong _ngcontent-c14="">Accounts</strong>
          </strong>
        </h2>
        <p _ngcontent-c14="">
          When you create an account with us, you must provide us information
          that is accurate, complete, and current at all times. Failure to do so
          constitutes a breach of the Terms, which may result in immediate
          termination of your account on our Service.
        </p>
        <p _ngcontent-c14="">
          You are responsible for safeguarding the password that you use to
          access the Service and for any activities or actions under your
          password, whether your password is with our Service or a third-party
          service.
        </p>
        <p _ngcontent-c14="">
          You agree not to disclose your password to any third party. You must
          notify us immediately upon becoming aware of any breach of security or
          unauthorized use of your account.
        </p>
        <h2 _ngcontent-c14="">
          <strong _ngcontent-c14="">
            <strong _ngcontent-c14="">Links To Other Web Sites</strong>
          </strong>
        </h2>
        <p _ngcontent-c14="">
          Our Service may contain links to third-party web sites or services
          that are not owned or controlled by Telyport.
        </p>
        <p _ngcontent-c14="">
          Telyport has no control over, and assumes no responsibility for, the
          content, privacy policies, or practices of any third party web sites
          or services. You further acknowledge and agree that Telyport shall not
          be responsible or liable, directly or indirectly, for any damage or
          loss caused or alleged to be caused by or in connection with use of or
          reliance on any such content, goods or services available on or
          through any such web sites or services.
        </p>
        <p _ngcontent-c14="">
          We strongly advise you to read the terms and conditions and privacy
          policies of any third-party web sites or services that you visit.
        </p>
        <h2 _ngcontent-c14="">
          <strong _ngcontent-c14="">
            <strong _ngcontent-c14="">Termination</strong>
          </strong>
        </h2>
        <p _ngcontent-c14="">
          We may terminate or suspend access to our Service immediately, without
          prior notice or liability, for any reason whatsoever, including
          without limitation if you breach the Terms.
        </p>
        <p _ngcontent-c14="">
          All provisions of the Terms which by their nature should survive
          termination shall survive termination, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity and limitations
          of liability.
        </p>
        <p _ngcontent-c14="">
          We may terminate or suspend your account immediately, without prior
          notice or liability, for any reason whatsoever, including without
          limitation if you breach the Terms.
        </p>
        <p _ngcontent-c14="">
          Upon termination, your right to use the Service will immediately
          cease. If you wish to terminate your account, you may simply
          discontinue using the Service.
        </p>
        <p _ngcontent-c14="">
          All provisions of the Terms which by their nature should survive
          termination shall survive termination, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity and limitations
          of liability.
        </p>
        <h2 _ngcontent-c14="">
          <strong _ngcontent-c14="">
            <strong _ngcontent-c14="">Governing Law</strong>
          </strong>
        </h2>
        <p _ngcontent-c14="">
          These Terms shall be governed and construed in accordance with the
          laws of Karnataka, India, without regard to its conflict of law
          provisions.
        </p>
        <p _ngcontent-c14="">
          Our failure to enforce any right or provision of these Terms will not
          be considered a waiver of those rights. If any provision of these
          Terms is held to be invalid or unenforceable by a court, the remaining
          provisions of these Terms will remain in effect. These Terms
          constitute the entire agreement between us regarding our Service, and
          supersede and replace any prior agreements we might have between us
          regarding the Service.
        </p>
        <h2 _ngcontent-c14="">
          <strong _ngcontent-c14="">
            <strong _ngcontent-c14="">Changes</strong>
          </strong>
        </h2>
        <p _ngcontent-c14="">
          We reserve the right, at our sole discretion, to modify or replace
          these Terms at any time. If a revision is material we will try to
          provide at least 15 days notice prior to any new terms taking effect.
          What constitutes a material change will be determined at our sole
          discretion.
        </p>
        <p _ngcontent-c14="">
          By continuing to access or use our Service after those revisions
          become effective, you agree to be bound by the revised terms. If you
          do not agree to the new terms, please stop using the Service.
        </p>
        <h2 _ngcontent-c14="">
          <strong _ngcontent-c14="">
            <strong _ngcontent-c14="">Contact Us</strong>
          </strong>
        </h2>
        <p _ngcontent-c14="">
          If you have any questions about these Terms, please contact us.
        </p>
      </div>
    </>
  );
}

export default TermsCondition;

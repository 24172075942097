import React from "react";

import whyManageImage from "../../assets/images/enterprises/enterprisewhy@2x.webp";
import whyCuttingImage from "../../assets/images/enterprises/Business@2x.webp";

function WhyEnterprise() {
  const data = {
    p1: [
      {
        title: "Technology & Business Analytics",
        content:
          "Get access to Telyport array of products, integrations other sales channels/marketplaces, real time order tracking, business analytics and much more",
        img: "",
      },
      {
        title: "Flexible Options",
        content:
          "Choose from fast, affordable, convenience and many more combinations that best suites your enterprise need",
        img: "",
      },
      {
        title: "Payments Simplified",
        content:
          "Payments with PCI Compliance. CODs, Settlements, Reconciliations and so on are on-demand and simplified",
        img: "",
      },
      {
        title: "Scale At Will",
        content:
          "Omni-channel sales has never been easier. Plug your business to Telyport and scale your On-Demand capacity at will delivering products in hours, not days",
        img: "",
      },
    ],
    p2: [
      {
        title: "APIs/Channel Integrations",
        content: "Integrations across major omni-channel marketplaces, digital stores, warehousing, last mile available via pre-built plugins as well as native APIs that can be extended to suit any business specific requirements",
        img: "",
      },
      {
        title: "Business Analytics",
        content: "Take charge of your data now with EDI extending to Enterprises deep insights, including inventory forecasting, realtime view of fulfillment and much more....Everything at the convenience of Plug & Play",
        img: "",
      },
      {
        title: "Admin Dashboard",
        content: "Track all your on-going orders realtime including ETAs, Status and much more... Empowering enterprises to make Informed Decisions",
        img: "",
      },
    ]
  };

  return (
    <>
      <h2 className="is-size-3 has-text-weight-bold has-text-centered my-6">
        Why Telyport for Enterprises?
      </h2>

      <div className="container is-fluid-custom">
        <div className="columns">
          <div className="column is-6">
            <h3 className="is-size-6 has-text-weight-bold mb-6">
              Focus On Your Core-Business While Telyport Augments Your Fulfillment Value Chain
            </h3>
            {data.p1.map((item, index) => {
              return (
                <div className="columns">
                  <div className="column is-1"></div>
                  <div className="column is-11">
                    <h4 className="is-size-6 has-text-weight-bold">
                      {item.title}
                    </h4>
                    <p className="is-size-6">{item.content}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="column is-6">
            <img src={whyManageImage} alt="Why Telyport for Enterprise" />
          </div>
        </div>
      </div>
      <div className="container is-fluid-custom">
        <div className="columns">
          <div className="column is-6">
            <img src={whyCuttingImage} alt="Why Telyport for Enterprise" />
          </div>
          <div className="column is-6">
            <h3 className="is-size-6 has-text-weight-bold mb-6">
              Deep Tech Capabilities & Technology That Augments Your Business Operations
            </h3>
            {data.p2.map((item, index) => {
              return (
                <div className="columns">
                  <div className="column is-1"></div>
                  <div className="column is-11">
                    <h4 className="is-size-6 has-text-weight-bold">
                      {item.title}
                    </h4>
                    <p className="is-size-6">{item.content}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default WhyEnterprise;

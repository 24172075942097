import React from "react";
import { Link } from "react-router-dom";
import "./index.scss";

function BannerD2C() {
  return (
    <>
      <section className="hero is-medium">
        <div className="hero-body has-text-centered">
          <p className="title py-4">Deep Tech Powered End-To-End Fulfillment For D2C Brands</p>
          <p className="subtitle pb-6">
          Signup for Hourly and Same Day Deliveries by bringing your Inventory even closer to delighting your beloved customers
          </p>
          <a href="https://console.telyport.com/register" className="button is-primary">Get Started</a>
        </div>
      </section>
    </>
  );
}

export default BannerD2C;

import React from "react";

import { Link } from "react-router-dom";

function Footer() {
  return (
    <nav
      style={{ background: "#000" }}
      className="navbar"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <p className="m-auto">© 2023, Telyport Technologies Pvt. Ltd.</p>
        </div>

        <div id="navbarBasicExample" className="navbar-menu is-desktop">
          <div className="navbar-end">
            <div className="navbar-item">
              <a className="navbar-item" href="/terms-and-condition">
                Terms & Conditions
              </a>
              <a className="navbar-item" href="/privacy">
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Footer;

import React from "react";
import { Link } from "react-router-dom";
import FootNote from "../../sub-components/footnote";
import BannerEnterprise from "./banner";
import "./index.scss";
import WhyEnterprise from "./why";

function Customers() {
  return (
    <>
      <div className="container is-fluid-custom is-max-widescreen">
        <BannerEnterprise />
        
      </div>
      <FootNote />
    </>
  );
}

export default Customers;

import React from "react";

import { Link } from "react-router-dom";

import appstoreimage from "../assets/images/app-stores/Apple app store@2x.webp";
import playstoreimage from "../assets/images/app-stores/Google play@2x.webp";

import instagramicon from "../assets/images/social-icons/Instagram.svg";
import facebookicon from "../assets/images/social-icons/Facebook.svg";
import twitterIcon from "../assets/images/social-icons/Twitter.svg";
import linkedinIcon from "../assets/images/social-icons/linkedin.svg";

function FootNote() {
  return (
    <div style={{ background: "#242424", marginTop: 64 }}>
      <div className="container is-fluid-custom py-6" style={{ color: "#fff" }}>
        <div className="columns">
          <div className="column is-7">
            <h4 className="has-text-weight-bold">CONNECT WITH US</h4>
            <div className="is-flex my-4">
              <div className="mr-2">
                <a
                  href="https://www.facebook.com/Telyportinc"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={facebookicon}
                    alt="Facebook"
                    style={{ height: 24, width: 24, objectFit: "contain" }}
                  />
                </a>
              </div>
              <div className="mx-2">
                <a
                  href="https://www.instagram.com/letstelyport/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={instagramicon}
                    alt="Instagram"
                    style={{ height: 24, width: 24, objectFit: "contain" }}
                  />
                </a>
              </div>
              <div className="mx-2">
                <a
                  href="https://twitter.com/telyportinc"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={twitterIcon}
                    alt="Twitter"
                    style={{ height: 24, width: 24, objectFit: "contain" }}
                  />
                </a>
              </div>
              <div className="mx-2">
                <a
                  href="https://in.linkedin.com/company/telyport"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={linkedinIcon}
                    alt="LinkedIn"
                    style={{ height: 24, width: 24, objectFit: "contain" }}
                  />
                </a>
              </div>
            </div>

            <h4 className="has-text-weight-bold">DOWNLOAD THE APP</h4>
            <div className="is-flex mt-3">
              <div className="mr-2">
                <a
                  href="https://apps.apple.com/in/app/telyport-delivery-app/id1437442582"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={appstoreimage}
                    alt="Telyport on app store"
                    style={{
                      objectFit: "cover",
                      objectPosition: "0 20%",
                      width: 120,
                    }}
                  />
                </a>
              </div>
              <div className="ml-2">
                <a
                  href="https://play.google.com/store/apps/details?id=com.telyport.telyportUser&hl=en_IN"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={playstoreimage}
                    alt="Telyport on play store"
                    style={{
                      objectFit: "cover",
                      objectPosition: "0 20%",
                      width: 120,
                    }}
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="column is-5">
            <div className="columns">
              <div className="column is-6">
                <h4>GET STARTED</h4>
                {/* TODO - Fix links */}
                <ul className="my-2 footnote-nav">
                  <li>
                    <a href="https://console.telyport.com/register">
                      Business signup
                    </a>
                  </li>
                  <li>
                    <a href="https://console.telyport.com/">Login</a>
                  </li>
                </ul>
              </div>
              <div className="column is-6">
                <h4>HELP CENTER</h4>
                <ul className="my-2 footnote-nav">
                  <li>
                    <a href="/contact-us">Contact us</a>
                  </li>
                  <li>
                  <a href="https://telyport.com/docs">API docs</a>
                  </li>
                  <li>
                    {/* <Link to="https://console.telyport.com/">How it works</Link> */}
                  </li>
                  <li>
                    <a href="/delete-account">Delete account</a>
                  </li>
                  <li>
                    <a href="/delete-agent-account">Delete agent account</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FootNote;

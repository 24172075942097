import React from "react";

import logo from "../assets/images/telyport@2x.png";

// TODO - Add service region as well as make the nav as per the design
function Header() {
  return (
    <nav className="navbar container is-fluid-custom py-2" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <a className="navbar-item" href="/">
          <img src={logo} width="112" height="28" alt="Telyport Logo"/>
        </a>

        {/* TODO - Make this mobile friendly, expand on click and show the nav */}
        {/* <button
          className="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </button> */}
      </div>

      <div id="navbarBasicExample" className="navbar-menu">
        

        <div className="navbar-end">
          <div className="navbar-item">
            {/* <a className="navbar-item" href="/customer">
              Consumers
            </a>
            <a className="navbar-item" href="/enterprises">
              Enterprises
            </a>
            <a className="navbar-item" href="/d2c">
              D2C
            </a>
            <a className="navbar-item" href="/msme">
              MSMEs
            </a> */}
            <div className="buttons">
              <a className="button is-primary" href="https://console.telyport.com">Business Login</a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header;

import axios from 'axios';

export const BASE_URL = "https://telyport.com/api/v2/beta/"

export const axiosClient = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
})
import React, { useEffect, useState } from 'react'
import { useSearchParams, useParams, useLocation } from 'react-router-dom'

function BulkOtp() {

  const idInPath = useLocation().pathname.split("/_o/").pop()
  const [searchParams] = useSearchParams();
  const idInQuery = searchParams.get("id")
  const id = (idInPath === "/_o") ? idInQuery : idInPath
  const pages = ["home", "loading", "page-not-found"]
  const [currentPage, setCurrentPage] = useState("loading")

  const [data, setData] = useState({})

  const expires_at = (ts) => {
      const date = new Date(ts);
      return date.toLocaleDateString() + " " + date.toLocaleTimeString();
    }

  useEffect(() => {
    fetch(`https://telyport.com/api/v2/beta/m/order/${id}/public/otp`)
      .then(res => {
        if (res.status !== 200) {
          setCurrentPage("page-not-found")
          return
        }
        return res.json()
      })
      .then(res => {
        if (!res) {
          setCurrentPage("page-not-found")
          return
        }
        setData(res)
        setCurrentPage("home")
      })
      .catch(err => {
        console.error(err)
        setCurrentPage("page-not-found")
      })
  }, [])

  return (
    <>
      <style>
        {
          `
           * {
      padding: 0;
      margin: 0;
    }
    html,
    body {
      height: 100%;
      overflow: hidden;
    }
    body {
      font-size: 16px;
    }

    #entry {
      height: 100%;
      display: flex;
      flex-direction: column;
      height: 100vh;
    }

    #main {
      width: 360px;
      margin-left: auto;
      margin-right: auto;
      padding: 8px;
      display: flex;
      flex-direction: column;
      height: 90vh;
    }
    #main > h2 {
      padding: 4px 12px;
      margin: 24px 0px 8px 0px;
      text-align: center;
      border-bottom: 1px solid #ddd;
    }
    #info {
      display: flex;
      margin-top: 1rem;
      margin-bottom: 8px;
      border-radius: 8px;
      top: 60px;
      background-color: white;
      margin: 12px;
    }
    #agent-detail {
      flex: 1;
      align-items: center;
    }
    #order-info {
      flex: 1;
    }

    #order-list {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      bottom: 0;
    }

    #order-list .scrollable-container {
      position: relative;
      overflow-y: auto;
      
    }

     /* WebKit browsers (Chrome, Safari, Edge) */
    #order-list .scrollable-container::-webkit-scrollbar {
      width: 0px; 
    }
    
    /* For Firefox */
    #order-list .scrollable-container {
      scrollbar-width: none; /* Makes the scrollbar thin */
    }

    #order-list .order-detail {
      margin-bottom: 8px;
      border-radius: 4px;
    }
    #order-list .order-detail .order-head {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 4px 0;
      padding: 4px 0;
      border-bottom: 1px solid #ccc;
    }
    #order-list .order-detail .user-details div {
      padding: 4px 0;
    }
    #order-list .order-detail .order-head small {
      color: #666;
    }
    .vr {
      border: none;
      border-left: 1px solid hsla(200, 10%, 50%, 100);
      margin-top: 10px;
      margin-bottom: 10px;
      width: 1px;
    }
    .items-space-evenly {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding: 1rem;
    }
    .rounded-img-md {
      height: 5rem;
      width: 5rem;
      border-radius: 50%;
      object-fit: cover;
      object-position: center top;
    }
    .centered-column-items {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 86vh;
      width: 100vw;
    }
    .message-head {
      margin-top: 26vh;
      font-size: 10vh;
    }
    .message {
      text-align: center;
    }
          `
        }
      </style>
        <div id="entry">
          {/* <div id="header">
            <img
              src="https://telyport.com/img/telyport@2x.png"
              alt="Telyport logo"
            />
          </div> */}
          <div id="root">
            {(currentPage==="home") && <div id="main">
              <div id="info">
                  <div id="agent-detail" className="items-space-evenly">
                    {data.agentInfo ? <><img
                      src={data.agentInfo.photoURL}
                      alt="agent_img"
                      id="agent-img"
                      className="rounded-img-md"
                    />
                    <span>{data.agentInfo.name}</span></>: <span>Agent is not assigned</span>}
                    <span style={{fontSize: "12px"}}>{data.phone}</span>
                  </div>
                  <div className="vr"></div>
                  <div id="order-info" className="items-space-evenly">
                    <span>OTP:</span>
                    <div style={{fontSize: "32px", letterSpacing: "8px"}}><b
                      >{data.otp}</b></div>
                    <span><small>Expires: {expires_at(data.expiresAt)}</small></span>
                    <span></span>
                  </div>
              </div>
              <h2>ORDERS</h2>
              <div id="order-list">
                <div className="scrollable-container">
                    <div className="order-detail">
                  {data.orders.map(order => (
                        <div key={order.orderID}><div className="order-head">
                          <span><b>{order.orderID}</b></span>
                          <span><small>{order.ship.type} | {order.pack.type}</small></span>
                    </div>
                      
                        <div className="user-details">
                          <div><b>Sender:</b> {order.sender.name}</div>
                          <div><b>Receiver:</b> {order.receiver.name}</div>
                        </div><hr /></div>
                  ))}
                    </div>
                </div>
              </div>
          </div>}
          {(currentPage==="loading") &&   <div className="centered-column-items">
        <div className="message-head">LOADING</div>
          </div>}
          {(currentPage==="page-not-found") &&   <div className="centered-column-items">
        <div className="message-head">404</div>
        <div className="message">Something is wrong with the link. Please check your
          link.</div>
      </div>}
          </div>
        </div>
    </>
  )
}

export default BulkOtp

import React from "react";

import { Link } from "react-router-dom";

function FlowerCards() {
  return (
    <div className="container is-fluid-custom is-max-widescreen flower-cards mt-6">
      <div className="columns">
        <div className="column is-2"></div>
        <div className="column is-8">
          <div className="tile is-ancestor">
            <div className="tile is-vertical">
              <div className="tile">
                <div className="tile is-parent is-vertical">
                  <article className="tile is-child">
                  <Link to="/enterprises">
                      <p className="is-size-5 has-text-weight-bold has-text-centered">
                        For Businesses
                      </p>
                      <p className="is-size-6 has-text-weight-normal has-text-centered">
                        Reach your customers in 2 Hrs, 4 Hrs or Sameday
                      </p>
                    </Link>
                  </article>
                  {/* <article className="tile is-child">
                    <Link to="/d2c">
                      <p className="is-size-5 has-text-weight-bold">
                        For D2C Brands
                      </p>
                      <p className="is-size-6 has-text-weight-normal">
                        Deliver to your customers doorstep as fast as 2 hrs eliminating your operational overheads that too with enhanced net promoter score
                      </p>
                    </Link>
                  </article> */}
                </div>
                <div className="tile is-parent is-vertical">
                  <article className="tile is-child">
                    <Link to="/customer">
                      <p className="is-size-5 has-text-weight-bold has-text-centered">
                        For Consumers
                      </p>
                      <p className="is-size-6 has-text-weight-normal has-text-centered">
                        Send packages affordably from your home
                      </p>
                    </Link>
                  </article>
                  {/* <article className="tile is-child">
                    <Link to="/msme">
                      <p className="is-size-5 has-text-weight-bold">For MSMEs</p>
                      <p className="is-size-6 has-text-weight-normal">
                        Reach customers city wide on the click of a button
                      </p>
                    </Link>
                  </article> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
}

export default FlowerCards;

import React from "react";

import citywideImage from "../../assets/images/home/Citywide@2x.webp";
import affordableImage from "../../assets/images/home/Affordable@2x.webp";
import convenienceImage from "../../assets/images/home/conve@2x.webp";
import secureImage from "../../assets/images/home/secure@2x.webp";
import payYourWayImage from "../../assets/images/home/pay your way@2x.webp";
import trackingImage from "../../assets/images/home/TRACKING@2x.webp";

function WhatYouGet() {
  const data = [
    {
      title: "Technology & Convenience",
      desc: "Telyport's technology and integrations make it easy for you to afford On-Demand deliveries. Get started with a few clicks and manage your deliveries from the comfort of your home",
      image: convenienceImage,
    },
    {
      title: "Reach Customers in Hours, not Days",
      desc: "Telyport's network of delivery partners and technology enables you to deliver your products to your customers in hours rather than days it takes traditional couriers",
      image: citywideImage,
    },
    {
      title: "Affordable Flat Rates for Citywide Deliveries",
      desc: "Telyport's technology and integrations make it easy for you to afford On-Demand deliveries for a wide range of items whether FMCG, Consumer Goods, Perishables or Lightly Packaged products. Reach customers anywhere in the city from ₹ 40 onwards",
      image: affordableImage,
    },
    {
      title: "Pay Per Use, No Hidden Costs",
      desc: "Telyport lets you use the platform On-Demand with no necessity for minimum business guarantee or any other hidden costs. Pay only for what you use and affordably scale your business",
      image: secureImage,
    },
    {
      title: "Pay Your Way",
      desc: "Simplified and highly flexible payment structure. Load your wallet or pay on-demand with UPI, credit/debit card, net banking or in cash. COD facility available at a minor surcharge",
      image: payYourWayImage,
    },
    {
      title: "Tracking & More",
      desc: "Track all your on-going orders within the tracking dashboard. Track ETA, order status and other state of the art features in realtime",
      image: trackingImage,
    },
  ];
  return (
    <>
      <div style={{ height: "64px" }}></div>

      <h2 className="is-size-3 has-text-weight-bold has-text-centered my-4">
        What you get with Telyport
      </h2>
      <div className="container is-fluid-custom is-max-desktop">
        {data.map((item, i) => (
          <div className={`columns is-vcentered ${i%2===0 ? 'is-flex-direction-row-reverse' : ''}` } key={i}>
            
            <div className="column is-5">
              <img
                src={item.image}
                alt={item.title}
                style={{
                  objectFit: "cover",
                  objectPosition: "0 20%",
                }}
              />
            </div>
            <div className="column is-7">
              <h3 className="is-size-4 has-text-weight-bold">{item.title}</h3>
              <p>{item.desc}</p>
            </div>
            
          </div>
        ))}
      </div>
    </>
  );
}

export default WhatYouGet;

import React from 'react'
import Banner from './banner'
import FlowerCards from './flower-cards'
import FootNote from '../../sub-components/footnote'
import './index.scss'
import WhatYouGet from './what-you-get'

function Home() {
  return (
    <>
      <Banner/>
      <FlowerCards />
      <WhatYouGet />
      <FootNote />
    </>
  )
}

export default Home
import React from "react";

import homeIllustration from "../../assets/images/home/Banner Illustration@2x.webp";
import appstoreIcon from "../../assets/images/app-stores/apple.svg";
import playstoreIcon from "../../assets/images/app-stores/google-play.svg";

function Banner() {
  return (
    // TODO Improve the banner
    <div className="container is-fluid-custom is-max-widescreen mt-4">
      <div className="columns">
        <div className="column is-6 m-auto">
          <h2 className="is-size-5">
          On-Demand Deliveries from <b>₹ 40</b>
          </h2>
          <h1 className="is-size-2 has-text-weight-bold my-4">
          On-Demand Citywide Deliveries at Flat Rates
          </h1>
          <p>
            
          Plug Telyport to your business and deliver to your customers faster, cheaper and more efficiently than ever before
          </p><br />
          <div className="banner-action mt-6 is-justify-content-left">
            <div className="mr-2">
              <a className="button is-primary is-size-5" href="http://onelink.to/dbw2cy" style={{
                borderRadius: 28,
                borderBottomLeftRadius: 0,

              }}>
                Download
                <img
                  src={playstoreIcon}
                  alt="Playstore"
                  style={{
                    height: 16,
                    width: 16,
                    marginLeft: 8,
                    objectFit: "contain"
                  }}
                />
                <img
                  src={appstoreIcon}
                  alt="Appstore"
                  style={{
                    height: 16,
                    width: 16,
                    marginLeft: 8,
                    objectFit: "contain"
                  }}
                />
              </a>
            </div>
            <div className="">
              <a className="button is-light is-size-5" href="https://console.telyport.com/register" style={{
                borderRadius: 28,
                borderBottomRightRadius: 0,
                
              }}>
                Telyport for business
              </a>
            </div>
          </div>
        </div>
        <div className="column px-6 py-0">
          <img
            src={homeIllustration}
            alt="Delivery partner delivering package to customer"
            style={{
              objectFit: "cover",
              objectPosition: "0 20%",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Banner;
